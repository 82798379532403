import { Layout, Input, Button, Divider,Form,Alert, Row } from 'antd';
import Header from '../components/header.js'
import Footer from '../components/footer.js';
import Typography from 'antd/es/typography/Typography.js';
import VesselDetails from '../components/vesselDetailsCard.js'
import DealerDetails from '../components/dealerDetailsCard.js'
import GenericFormInput from '../components/genericFormInput.js'
import { useEffect, useState,useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { EditOutlined,MinusCircleOutlined,PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;
const {Sider, Content} = Layout
const contentStyle = {
  minHeight: "100%",
  lineHeight: '60px',
  color: '#fff',
  height: "calc(100vh - 24vh)"
};
const siderStyle = {
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#f5f5f5',
    marginTop: 30
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 0,
      },
    },
  };

export default function RegisterParcels() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const parcels = Form.useWatch('parcels', form);
  const vessel = Form.useWatch('vessel_name', form);
  const imo = Form.useWatch('vessel_imo', form);
  const [parcelRes, setParcelRes] = useState()
  const [active] = useState()
  const [used] = useState()
  const [show,setShow] = useState(false)
  const [buttonDisabled,setButtonDisabled] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [parcelType, setParcelType] = useState()
  const [fieldLength, setFieldLength] = useState(0)
  const ref = useRef(null);
  useEffect(() => {
    if (parcels){
      if (parcels[0] && parcels[0].length === 8){
        sessionStorage.setItem('parcelNumber', [parcels])
        fetch(`${process.env.REACT_APP_API_URL}/register-parcels?parcel=${parcels[0]}&system=develon.sampleship.com`).then(res => res.json()
        .then(p => {
          console.log(p)
          if (p.parcel.error){
            setButtonDisabled(true)
            setError(true)
            setErrorMessage(p.parcel.error)
          } else {
            
            setParcelRes(p.parcel); setParcelType(p.parcel.parcel.owner); setShow(true);setButtonDisabled(false);
          }
        }))
      } else {
        setError(false)
        setShow(false)
        setButtonDisabled(true)
        setParcelRes()
      }
    }

  },[parcels,active,error,errorMessage,used])
  
  function onFinish(){
    if (parcelRes.parcel.generic && parcelRes.parcel.owner === 'vessel'){
      var parcel = parcelRes
      parcel.vessel = {name: vessel, imo: imo}
      setParcelRes(parcel)
      navigate('/collection-details', { state: {parcel: parcelRes,parcelNumbers:parcels, showVessel: show}});
    } else if (parcelRes.parcel.generic && parcelRes.parcel.owner === 'dealer') {
      var parcel_update = parcelRes
      parcel_update.dealer = {name: vessel, dealerId: imo}
      setParcelRes(parcel_update)
      navigate('/collection-details', { state: {parcel: parcelRes,parcelNumbers:parcels, showVessel: show}});
    } else {
      var parcel = parcelRes
      parcel.vessel = {name: parcelRes.vessel.name,parcelNumbers:parcels, imo: parcelRes.vessel.imo}
      setParcelRes(parcel)
      navigate('/collection-details', { state: {parcel: parcelRes, parcelNumbers:parcels , showVessel: show}});
    }
  }
  return (        
    <Layout>
        <Header />
        <Layout style={contentStyle} hasSider>
            <Sider width={420} style={siderStyle}>{(parcelType && parcelType === 'vessel' ? <VesselDetails vessel={vessel} imo={imo} parcelType={parcelType} parcelNumbers={parcels} data={parcelRes} show={show} />:null)}{(parcelType && parcelType === 'dealer' ? <DealerDetails dealer={vessel} dealerId={imo} parcelNumbers={parcels} parcelType={parcelType} data={parcelRes} show={show} />:null)}</Sider>
            <Content style={{marginLeft: 10}}>
                <Title level={3}> <EditOutlined style={{marginRight: 8}} color='black' /> Register Parcel(s)</Title>
                <Divider />
                <Typography>Please enter your parcel number(s) below to begin the registration process.</Typography>
                <Form style={{marginTop:10}} form={form} initialValues={{ parcels: [''] }} onFinish={onFinish} onFinishFailed={(e) => {console.log(e)}}>
                <Form.List name="parcels" rules={[
          {
            validator: async (_, parcels) => {
              if (!parcels || parcels.length > 2) {
                return Promise.reject(new Error('Only 2 Parcels can be Registered per shipment.'));
              }
            },
          },
        ]}>
        {(fields, { add, remove }, { errors }) => {
          setFieldLength(fields.length)
          return (
          <>
            {fields.map((field, index) => (
              
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input a parcel number",
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder='Enter Parcel Number...' style={{width:"20%"}} />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                ref={ref}
                icon={<PlusOutlined />}
              >
                Add Parcel
              </Button>
              
            </Form.Item>
          </>
        )}}
      </Form.List>
               {(error ? <Alert style={{maxWidth:700}} type='error' message={errorMessage} showIcon/>:null)}
               {(parcelRes && parcelRes.parcel.generic ? <GenericFormInput form={form} parcelType={parcelType} setButtonDisabled={setButtonDisabled} /> : null)}
               {(fieldLength > 1 ? <><strong>Please Note: </strong><p>If you have 2 samples to send, please combine and send together using 1 Shipment Airway Bill.</p><p>Please repeat the above process for additional samples</p></>:null)}
               <Button style={{marginTop:10}} htmlType="submit" >Next</Button>
               </Form>
               {/* <a onClick={() => {navigate('/register-packages')}}>Don't have a parcel number?</a> */}
            </Content>
        </Layout>
        <Footer />
    </Layout>
    );
  }