import { Layout,Space, Divider,Row,Col,Button,notification,Alert } from 'antd';
import Header from '../components/header.js'
import Footer from '../components/footer.js';
import Typography from 'antd/es/typography/Typography.js';
import VesselDetails from '../components/vesselDetailsCard.js'
import DateTimePortDetails from '../components/dateTimePortDetailsCard.js'
import CollectionDetailsCard from '../components/collectionDetailsCard.js'
import { ScheduleOutlined,FieldTimeOutlined,DownloadOutlined,RollbackOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import { useEffect } from 'react';
import { useLocation,useNavigation } from "react-router-dom";
const { Title,Paragraph } = Typography;
const {Sider, Content} = Layout
const contentStyle = {
  lineHeight: '60px',
  color: '#fff',
  minHeight: "calc(100vh - 120px)"
};
const siderStyle = {
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#f5f5f5',
  marginTop: 30, 
  marginLeft:10
};

const columns = [
    {
      title: 'Shipment Documents',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        console.log("THIS IS REDCORD")
        console.log(record)
        if (record === null){
          return;
        }
        return (
          <Space size="middle">
          <Button href={record.url} type="primary" icon={<DownloadOutlined />}>{record.name}</Button>
        </Space>
        )}}
  ];


export default function Documents(props) {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigation();
  const {state} = useLocation();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Shipment Successfully Created!",
      placement: 'bottomRight',
      maxCount:1
    });
  };
    useEffect(() => {
      if (state.shipment){
        openNotificationWithIcon('success')
      }
    },[])
    console.log(state.shipment.MSDSDocument === null)
    return (        
        <Layout>
        <Header />
        <Layout style={contentStyle} hasSider>
        {contextHolder}
        <Sider width={420} style={siderStyle}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <VesselDetails />
          <DateTimePortDetails />
          <CollectionDetailsCard />
        </Space>
        </Sider>
        <Content style={{marginLeft: 10}}>
            <Row>
            <Col span={24}>
            <Title level={3}><ScheduleOutlined style={{color:'black', margin:10}}/>Documentation</Title>
            <Divider />
            <Paragraph>Please download all documents below. </Paragraph>
            <Paragraph>Please print the documents and insert them into a sticky envelope and attach to the shipment. </Paragraph>
            <Table pagination={false} style={{maxWidth:700}} columns={columns} dataSource={[{key: '1', name: `AWB ${state.shipment.shipment.awb_number}`, url: state.shipment.AWBDocument},{key: '2', name: `Commercial Invoice ${state.shipment.shipment.awb_number}`, url: state.shipment.CommericalInvoiceDocument},(state.shipment.MSDSDocument === null ? null :{key: '3', name: `MSDS`, url: state.shipment.MSDSDocument})]} />
            </Col>
            </Row>
            <Row>
            <Col span={24}>
              {(state.collectionRequired ?

              <>
              <Title level={3}><FieldTimeOutlined  style={{color:'black', margin:10}} />Collection</Title>
              <Divider/>
              {/* Create new component returning collection details as complexity is too large for inline, also render error messages as API returns Response instead of Shipment when fail occurs */}
              {(state.shipment.shipment.pieces.collection_reference.length > 16 ? 
               <>
                <Paragraph>
                <Alert style={{maxWidth:800}} message='Collection Error' showIcon description='A collection could not be scheduled at your requested times.' type='warning'></Alert>
           </Paragraph>
           <Paragraph>
              Please try re-scheduling your collection by clicking below or contact us for further support at <a href='#'>support@samplelogistics.com.</a>
           </Paragraph> 
           <Button icon={<ScheduleOutlined/>} type='primary'>Schedule Collection</Button> 
               </>
               :  
               <>
               <Paragraph>
               Your Collection Reference Number is : <Tag size='large' style={{fontWeight:'bold'}} color="success">{state.shipment.shipment.pieces.collection_reference}</Tag>
           </Paragraph>
           <Paragraph>
               Please take note of this number for future reference or if you need to amend your collection.
           </Paragraph> 
              </>
              )}
                 
              </>     
              : null)}
            </Col>
            </Row>
            <Divider />
               <Paragraph>
                  Thank you for using SampleShip!
              </Paragraph>
              <Paragraph>
              If you require further assistance, please email us at <a href='#'>contact@samplelogistics.com</a> or visit our website <a href='https://samplelogistics.com'>samplelogistics.com</a>.
              </Paragraph>
              <Button onClick={() => {window.location.assign('/welcome')}} icon={<RollbackOutlined />} type='primary'>Send Another Parcel</Button>
        </Content>
        </Layout>
        <Footer />
    </Layout>
    );
  }